import GuidUtils from '@/shared/utils/guid'
import schoolTerms from '@/shared/enums/schoolTerms'

export default class WaitingList {
  constructor({
    id,
    classId,
    localTrainingName,
    name,
    responsible,
    gender,
    genderText,
    schoolTerm,
    birthDate,
    district,
    phone1,
    phone2,
    phone3,
    rg,
    cpf,
    observation,
    hasDeficiency,
    registrationDate,
    dateLastContactTest,
    localTrainingsModalities,
    tests,
    contacts,
    dateUpdate,
    cidCode,
    cidCategory,
    deficiencyId
  } = {}) {
    this.id = id || GuidUtils.emptyGuid,
    this.classId = classId,
    this.localTrainingName = localTrainingName,
    this.name = name,
    this.responsible = responsible,
    this.gender = gender,
    this.genderText = genderText,
    this.schoolTerm = schoolTerm,
    this.schoolTermText = this.getSchoolTerm(schoolTerm),
    this.birthDate = birthDate,
    this.district = district,
    this.phone1 = phone1,
    this.phone2 = phone2,
    this.phone3 = phone3,
    this.rg = rg,
    this.cpf = cpf,
    this.observation = observation,
    this.hasDeficiency = hasDeficiency ? 1 : 0,
    this.registrationDate = registrationDate,
    this.dateLastContactTest = dateLastContactTest,
    this.localTrainingsModalities = localTrainingsModalities ? localTrainingsModalities : [],
    this.localsTrainingModalities = this.getLocalTrainingsModalitiesNames(localTrainingsModalities),
    this.tests = tests ? tests : [],
    this.contacts = contacts ? contacts : [],
    this.dateUpdate = dateUpdate,
    this.cidCode = cidCode,
    this.cidCategory = cidCategory,
    this.deficiencyId = deficiencyId
  }

  getLocalTrainingsModalitiesNames(localsTrainingModalities) {
    if (!localsTrainingModalities) return

    const localsModalitiesNames = localsTrainingModalities.map(element => {
      const name = element.localTrainingName
      
      if(!element.modalityName)
        return name

      return `${element.localTrainingName} - ${element.modalityName}`
    })

    return localsModalitiesNames.join(' | ')
  }

  getSchoolTerm(schoolTerm){
    const schoolTermValues = Object.values(schoolTerms)
    const schoolTermObj = schoolTermValues.find(element => element.id === schoolTerm)

    if(!schoolTermObj)
      return ''

    return schoolTermObj.text
  }
}