import Vue from 'vue';

export const phoneFilter = (phone) => {
    if (!phone) return '-'

    if (phone.length <= 9)
      phone = `14${phone}`

    return phone.replace(/[^0-9]/g, '')
                .replace(/^\s*(\d{2}|\d{0})[-. ]?(\d{5}|\d{4})[-. ]?(\d{4})[-. ]?\s*$/, '($1) $2-$3');
  }

  export const phoneFilterBrasil = (phone) => {
    if (!phone) return '-'
  
    const x = phone.replace('+55', '')
                   .replace(/^\s*(\d{2}|\d{0})[-. ]?(\d{5}|\d{4})[-. ]?(\d{4})[-. ]?\s*$/, '($1) $2-$3')
    
    return x
  }

Vue.filter('ToPhoneFormatted', phoneFilter)
