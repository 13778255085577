const yesNo = {
  NO: { 
    id: 0, 
    name: 'Não' 
  },

  YES: { 
    id: 1, 
    name: 'Sim' 
  },
}

Object.freeze(yesNo)

export default yesNo