import ContactTypeRepository from '@/shared/http/repositories/socialProject/contact-type'
import Contact from '@/shared/models/waitinglistweb/contact'
import { loading, toast } from '@/shared/utils/UIHelper'
import { IsSuccessCode } from '@/shared/utils/API'
import { TheMask } from 'vue-the-mask'
import { phoneFilter } from '@/shared/filters/ToPhoneFormatted'

export default {
  props: {
    contacts: Array
  },
  componentes: {
    TheMask
  },
  data:() => ({
    contact: new Contact(),
    showModalContact: false,
    showModalDeleteContact: false,
    contactSelectedIndex: null,
    contactTypes: [],
    emptyContactsText: 'Não há contatos cadastrados',
    fields: [
      {
        key: 'contactTypeId',
        label: 'TIPO',
        class: 'left-header-border text-center',
        formatter: 'getContactTypeName'
      },
      {
        key: 'name',
        label: 'NOME',
        class: 'middle-header text-center'
      },
      {
        key: 'phone1',
        label: 'TELEFONE - 1',
        class: 'middle-header text-center',
        formatter: phoneFilter
      },
      {
        key: 'phone2',
        label: 'TELEFONE - 2',
        class: 'middle-header text-center'
      },
      {
        key: 'phone3',
        label: 'TELEFONE - 3',
        class: 'middle-header text-center'
      },
      {
        key: 'email',
        label: 'E-MAIL',
        class: 'middle-header text-center'
      },
      {
        key: 'action',
        label: 'AÇÕES',
        class: 'right-header-border text-center'
      }
    ]
  }),

  created() {
    this.loadContactType()
  },

  methods: {
    getContactTypeName (contactTypeId) {
      const contactType = this.contactTypes.find(contact => contact.id == contactTypeId)

      if(!contactType)
        return

      return contactType.name
    },

    loadContactType () {
      loading.push()
      this.contactTypes = []

      ContactTypeRepository.GetAll()
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.contactTypes = res.data.data

          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Não foi possível carregar a lista de Tipos de Contato.', 'ERRO')
        })
    },

    onSelectContactType () {
      if (!this.contact.contactTypeId) return

      this.contact.contactTypeName = this.getContactTypeName(this.contact.contactTypeId)
    },

    clearForm () {
      this.contact = new Contact()
    },

    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },

    onSave () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.mergeItemList()
          this.clearForm()
          this.showModalContact = false
        } else {
          toast.error('É necessário preencher os campos obrigatórios antes de continuar', 'ERRO')
        }
      })
    },

    onEditContact(contact) {
      this.contactSelectedIndex = contact.index
      this.contact = new Contact(contact.item)
      this.showModalContact = true
    },

    mergeItemList () {
      const isEditing = this.contactSelectedIndex != null

      if(isEditing) {
        this.contacts.splice(this.contactSelectedIndex, 1, this.contact)
        return
      }

      this.contacts.push(this.contact)
    },

    onAddContact () {
      this.clearForm()
      this.contactSelectedIndex = null
      this.showModalContact = true
    },

    onInactive () {
      this.removeContact()
      this.showModalDeleteContact = false
      this.contactSelectedIndex = null
    },

    onShowModalDeleteContact(index) {
      this.contactSelectedIndex = index
      this.showModalDeleteContact = true
    },

    removeContact () {
      const index = this.contactSelectedIndex
      const hasElement = this.contacts[index] != null

      if (!hasElement) {
        toast.error('Não foi possível excluir o contato', 'ERRO')
        return
      }

      this.contacts.splice(index, 1)
      this.showModalDeleteContact = false
    }
  }
}
