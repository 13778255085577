import ClassRepository from '@/shared/http/repositories/socialProject/class'
import ModalityLocalTrainingRepository from '@/shared/http/repositories/socialProject/modalitylocaltraining'
import WaitingListTest from '@/shared/models/waitinglist-test'
import TestSituation from '@/shared/enums/testSituation'
import { loading, toast } from '@/shared/utils/UIHelper'
import { IsSuccessCode } from '@/shared/utils/API'
import { dateFormattedFilter } from '@/shared/filters/ToDateFormatted'

export default {
  props: {
    tests: {
      type: Array,
      default: []
    }, 
    childName: {
      type: String,
      default: null
    }
  },
  data:() => ({
    emptyCreateTest: ' Não há testes cadastrados',
    showModalCreateTest: false,
    showModalDeleteTests: false,
    testSelectedIndex: null,
    createTestClasses: [],
    listLocalTraining: [],
    classes: [],
    test: new WaitingListTest(),
    modalityLocalTraining: [],
    modalities: [],
    testSituations: Object.values(TestSituation),
    fieldsCreateTests: [
      {
        key: 'className',
        label: 'TURMA',
        class: 'left-header-border text-center'
      },
      {
        key: 'result',
        label: 'RESULTADO',
        class: 'middle-header text-center',
        formatter: 'getTestSituationName'
      },
      {
        key: 'date',
        label: 'TESTE MARCADO EM',
        class: 'middle-header text-center',
        formatter: dateFormattedFilter
      },
      {
        key: 'action',
        label: 'AÇÕES',
        class: 'right-header-border text-center'
      }
    ]
  }),

  created() {
    this.loadModalityLocalTraining()
  },

  methods: {
    getTestSituationName (testSituationId) {
      const testSituation = this.testSituations.find(test => test.id == testSituationId)

      if(!testSituation)
        return

      return testSituation.name
    },

    loadModalityLocalTraining () {
      this.modalityLocalTraining = []
      loading.push()
      ModalityLocalTrainingRepository.GetAll()
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.modalityLocalTraining = res.data.data
          this.onSelectModality()
          this.onSelectLocalTraining()

          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Não foi possível carregar a lista de Modalidades e Locais de treinamento.', 'ERRO')
        })
    },

    onSave () {
      this.$validator.validateAll().then(isValid => {
        if (!isValid){
          toast.error('É necessário preencher os campos obrigatórios antes de continuar', 'ERRO')
          return
        }
        
        this.mergeItemList()
        this.clearForm()
        this.showModalCreateTest = false
      })
    },

    mergeItemList () {
      const isEditing = this.testSelectedIndex != null

      if(isEditing) {
        this.tests.splice(this.testSelectedIndex, 1, this.test)
        return
      }

      this.tests.push(this.test)
    },

    clearForm () {
      this.test = new WaitingListTest()
      this.test.result = TestSituation.PENDING.id
    },

    onAddTest () {
      this.clearForm()
      this.testSelectedIndex = null
      this.showModalCreateTest = true
    },

    onSelectClasse () {
      if (!this.test.classId) return

      const classe = this.classes.find(classe => classe.id === this.test.classId)

      if (!classe) return

      this.test.className = classe.name
    },

    getAllClasses() {
      if (!this.test.localTrainingId || !this.test.modalityId) return

      this.classes = []
      this.onSelectModality()
      
      loading.push()
      ClassRepository.GetAllByLocalTrainingAndModality(this.test.localTrainingId, this.test.modalityId)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          const hasClass = this.test.classId
          
          if(hasClass) {
            const hasClassId  = this.classes.some(classe => classe.id === this.test.classId)
            if(!hasClassId){
              this.test.classId = null
              this.classes = []
            }
          }

          this.classes = res.data.data
          
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar turmas', 'ERRO')
        })
    },

    onInactive () {
      this.removeTest()
      this.showModalDeleteTests = false
      this.testSelectedIndex = null
    },

    onShowModalDeleteTest(test) {
      this.testSelectedIndex = test
      this.showModalDeleteTests = true
    },

    onEditTest(test) {
      this.testSelectedIndex = test.index
      this.test = new WaitingListTest(test.item)
      this.onSelectModality()
      this.onSelectLocalTraining()
      this.getAllClasses()
      this.test.modalityId = test.item.modalityId
      this.showModalCreateTest = true
    },

    removeTest () {
      const index = this.testSelectedIndex
      const hasElement = this.tests[index] != null
  
      if (!hasElement) {
        toast.error('Não foi possível excluir o contato', 'ERRO')
        return
      }

      this.tests.splice(index, 1)
      this.showModalDeleteTests = false
    },

    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },

    onSelectLocalTraining () {
      let list = this.modalityLocalTraining

      if (this.test.localTrainingId) {
        const type = this.listLocalTraining.filter(s => s.id === this.test.localTrainingId)

        if (type.length > 0) {
          this.test.localTrainingName = type[0].name
          list = this.modalityLocalTraining.filter(s => s.localTrainingId === type[0].id)
        }
      }

      this.modalities = []
      for (var i = 0; i < list.length; i++) {
        let modality = {
          id: list[i].modalityId,
          name: list[i].modalityName
        }

        if (this.modalities.filter(m => m.id === modality.id).length === 0) {
          this.modalities.push(modality)
        }
      }
    },

    onSelectModality () {
      let list = this.modalityLocalTraining

      if (this.test.modalityId) {
        let type = this.modalities.filter(s => s.id === this.test.modalityId)

        if (type.length > 0) {
          this.test.modalityName = type[0].name
          list = this.modalityLocalTraining.filter(s => s.modalityId === type[0].id)
        }
      }

      this.listLocalTraining = []
      for (var i = 0; i < list.length; i++) {
        const local = {
          id: list[i].localTrainingId,
          name: list[i].localTrainingName
        }

        if (this.listLocalTraining.filter(l => l.id === local.id).length === 0) {
          this.listLocalTraining.push(local)
        }
      }
    }
  }
}
