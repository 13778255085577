const schoolTerms = {
  MORNING: { 
    id: 0, 
    name: 'Manhã' 
  },

  AFTERNOON: { 
    id: 1, 
    name: 'Tarde' 
  },

  INTEGRAL: { 
    id: 2, 
    name: 'Integral' 
  },

  WITHOUT_PERIOD: { 
    id: 3, 
    name: 'Sem Período' 
  }
}

Object.freeze(schoolTerms)

export default schoolTerms
