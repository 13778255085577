import GuidUtils from '@/shared/utils/guid'

export default class LocalTrainingModality {
  constructor({
    id,
    localTrainingId,
    localTrainingName,
    modalityId,
    modalityName
  } = {}) {
    this.id = id || GuidUtils.emptyGuid,
    this.name = name,
    this.localTrainingId = localTrainingId,
    this.localTrainingName = localTrainingName,
    this.modalityId = modalityId,
    this.modalityName = modalityName
  }
}