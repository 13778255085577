import SocialProjectManager from '../../clients/abdaSocialProjectManager'

const resourceName = 'contacttype'

const GetAll = () => SocialProjectManager.get(resourceName)
const GetById = id => SocialProjectManager.get(`${resourceName}/${id}`)

const ContactTypeRepository = {
  GetAll,
  GetById
}

export default ContactTypeRepository