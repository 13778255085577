import GuidUtils from '@/shared/utils/guid'

export default class WaitingListTest {
  constructor({
    id,
    waitingListId,
    name,
    classId,
    localTrainingId,
    modalityId,
    className,
    date,
    result,
    resultText,
    observation
  } = {}) {
    this.id = id || GuidUtils.emptyGuid,
    this.waitingListId = waitingListId,
    this.name = name,
    this.classId = classId,
    this.localTrainingId = localTrainingId,
    this.modalityId = modalityId,
    this.className = className,
    this.date = date,
    this.result = result,
    this.resultText = resultText,
    this.observation = observation
  }
}
