import SocialProjectManager from '../../clients/abdaSocialProjectManager'

const resourceName = 'deficiency'
const resourceStudent = 'waitinglist'

const GetById = waitingListId => SocialProjectManager.get(`${resourceStudent}/${waitingListId}/${resourceName}`)
// const GetAll = waitingListId => SocialProjectManager.get(`${resourceStudent}/${waitingListId}/${resourceName}`)

const Create = data => SocialProjectManager.post(`${resourceStudent}/${data.waitingListId}/${resourceName}`, data)

const Update = data => SocialProjectManager.put(`${resourceStudent}/${data.waitingListId}/${resourceName}/${data.id}`, data)

const Delete = (waitingListDeficiencyId) => SocialProjectManager.delete(`${resourceStudent}/${resourceName}/${waitingListDeficiencyId}`)

const WaitingListDeficiencyRepository = {
  GetById,
  // GetAll,
  Create,
  Update,
  Delete
}

export default WaitingListDeficiencyRepository