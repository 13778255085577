import Vue from 'vue'
import moment from 'moment'

export const dateFormattedFilter = (value) => {
  if (!value) return '-'

  moment.locale('pt-br')

  const d = moment(value, moment.ISO_8601)

  if (d.isValid()) {
    return d.format('DD/MM/YYYY')
  }

  return '-'
}

export const dateFormattedFilterCustom = (value, format) => {
  if (!value || !format) return '-'

  moment.locale('pt-br')

  const d = moment(value, moment.ISO_8601)

  if (d.isValid()) {
    return d.format(format)
  }

  return '-'
}

export const dateHourFormattedFilter = (value) => {
  if (!value) return '-'

  moment.locale('pt-br')

  const d = moment(value, moment.ISO_8601)

  if (d.isValid()) {
    return d.format('DD/MM/YYYY HH:mm')
  }

  return '-'
}

Vue.filter('ToDateFormatted', dateFormattedFilter)
