import WaitingListRepository from '@/shared/http/repositories/socialProject/waitinglist'
import BreadCrumb from "@/components/layout/BreadCrumb/breadCrumb.vue"
import WaitingListContact from './waitinglist-contact/WaitingListContact.vue'
import WaitingListLocalTraining from './waitinglist-localtraining/WaitingListLocalTraining.vue'
import WaitingListTest from './waitinglist-test/WaitingListTest.vue'
import WaitingList from '@/shared/models/waitinglist'
import YesNo from '@/shared/enums/yesNo'
import GuidUtils from '@/shared/utils/guid'
import SchoolTerms from '@/shared/enums/schoolTerms'
import { loading, toast } from '@/shared/utils/UIHelper'
import { dateFilter } from '@/shared/filters/ToDate'
import { dateHourFormattedFilter } from '@/shared/filters/ToDateFormatted'
import { IsSuccessCode } from '@/shared/utils/API'
import { TheMask } from 'vue-the-mask'
import DeficiencyTypeRepository from '@/shared/http/repositories/socialProject/deficiency-type'
import WaitingListDeficiencyRepository from '@/shared/http/repositories/socialProject/waitinglist-deficiency'
import CidRepository from '@/shared/http/repositories/socialProject/cid'
import moment from 'moment'
import WaitingListDeficiency from '@/shared/models/studentDeficiency'

export default {
  components: {
    [BreadCrumb.name]: BreadCrumb,
    WaitingListLocalTraining,
    WaitingListTest,
    WaitingListContact,
    TheMask
  },

  data:() => ({
    waitingListDeficiency: new WaitingListDeficiency(),
    waitingList: null,
    disabledAllInputCid: false,
    optionsPeriodSchool: Object.values(SchoolTerms),
    showModalRemoveDeficiency: false,
    showButtonRemoveDeficiency: false,
    dateFormatted: null,
    yesNoOptions: Object.values(YesNo),
    accountUpdated: [],
    showModalInfoAccountUpdated: false,
    isNew: null,
    genders: [
      { id: 0, text: 'Masculino' },
      { id: 1, text: 'Feminino' }
    ],
    showCid: false,
    cidCategories: [],
    cidDescription: null,
    allCidCode: null
  }),

  computed: {
    breadCrumb() {
      return {
        breadcrumbItems: [
          {
            text: 'Dashboard',
            href: this.$router.resolve({ name: 'Dashboard' }).href
          },
          {
            text: 'Listagem de Espera',
            href: this.$router.resolve({ name: 'WaitingList' }).href
          },
          {
            text: 'Criança',
          }
        ]
      }
    },
    formatDateRegistration() {
      const result = dateHourFormattedFilter(this.waitingList.registrationDate)

      if (result == '-') {
        const currentDate = moment().format("DD/MM/YYYY")
        return currentDate
      }

      return result
    }
  },

  created () {
    this.clearForm()
    this.$validator.reset()

    this.isNew = !this.$route.params.id || GuidUtils.isEmpty(this.$route.params.id)

    if(!this.isNew)
      this.getById()
  },
  
  methods: {
    getDateUpdate(date) {
      if (!date) return 'Não informado'

      const dateUpdate = dateHourFormattedFilter(date)
      return dateUpdate
    },

    getAccountNameUpdate(id) {
      if (!id) return 

      if (GuidUtils.isEmpty(id)) return
      
      loading.push()
      WaitingListRepository.GetAccountUpdated(id)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.accountUpdated = res.data.data
         
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar conta que fez a alteração', 'ERRO')
        })
    },

    checkHasDeficiency() {
      if (this.waitingList.hasDeficiency != 1) {
        this.showCid = false
        this.waitingList.cidCode = null
        this.cidDescription = null
        this.waitingList.cidCategory = null
        return
      }

      this.getAllDeficiencyType()
      this.showCid = true
    },

    getCidDescription (cidCode) {

      if (!cidCode) {
        this.cidDescription = null
        return
      }

      loading.push()
      CidRepository.GetByCidCode(cidCode)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          const objectCid = res.data.data

          this.cidDescription = objectCid.description
         
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          this.cidDescription = null
          this.waitingList.cidCode = null
          toast.info('CID não encontrado', 'AVISO')
        })
    },

    getAllCidCode (cidCode) {

      if (!cidCode) {
        this.cidDescription = null
        return
      }

      if (cidCode.length < 2) return

      loading.push()
      CidRepository.GetAllCidCode(cidCode)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          const cidCodes = res.data.data

          const onlyCidCode = cidCodes.map(x => x.cidCode).sort()

          if (!onlyCidCode) {
            toast.info('CID NÃO ENCONTRADO', 'NÃO ACHEI O CID')
            return
          }

          this.allCidCode = onlyCidCode
         
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          this.allCidCode = null
          this.student.cidCode = null
          toast.info('CID não encontrado', 'AVISO')
        })
    },

    getAllDeficiencyType () {
      loading.push()
      DeficiencyTypeRepository.GetAll()
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.cidCategories = res.data.data

          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao pegar os tipos de deficiência', 'ERRO')
        })
    },

    getById() {
      loading.push()
      WaitingListRepository.GetById(this.$route.params.id)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.waitingList = new WaitingList(res.data.data)
          this.getAccountNameUpdate(res.data.data.accountIdUpdate)

          this.checkHasDeficiency()
          
          if (this.waitingList.hasDeficiency == 1) this.getDeficiencyId()

          this.$validator.reset()
          
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar criança', 'ERRO')
        })
    },

    onSave () {
      this.$validator.validateAll().then(isValid => {
        if (!isValid) {
          toast.warning('É necessário preencher os campos obrigatórios antes de continuar', 'CAMPOS EM BRANCO')
          return
        }

        const hasConcact = this.waitingList.contacts && this.waitingList.contacts.length > 0

        if(!hasConcact){
          toast.warning('É necessário inserir pelo menos um contato antes de continuar', 'CONTATO')
          return
        }

        const hasLocalTrainingModality = this.waitingList.localTrainingsModalities && this.waitingList.localTrainingsModalities.length > 0

        if(!hasLocalTrainingModality){
          toast.warning('É necessário inserir pelo menos um local de treinamento/modalidade antes de continuar', 'TREINAMENTO/MODALIDADE')
          return
        }
        
        const hasDeficiency = this.waitingList.hasDeficiency == 1
        
        if (hasDeficiency) {
          const inputBlank = this.cidDescription == null || this.waitingList.cidCategory == null
          if (inputBlank) {
            toast.warning('É necessário informar qual o CID da criança e a categoria da deficiência', 'CID - DEFICIÊCIA')
            return
          }
        }

        const isNew = GuidUtils.isEmpty(this.waitingList.id)

        this.waitingList.dateUpdate = null

        if(isNew){
          this.onSaveCreate()
          return
        }

        this.onSaveEdit()        
      })
    },

    clearForm() {
      this.waitingList = new WaitingList()
      this.waitingList.hasDeficiency = YesNo.NO.id
    },

    onSaveCreate() {
      loading.push()
      WaitingListRepository.Create(this.waitingList)
        .then(result => {
          if(!IsSuccessCode(result)) return Promise.reject()
          
          toast.success('Registro salvo com sucesso', 'SUCESSO')
          this.waitingList.id = result.data.data.id

          if (this.waitingList.hasDeficiency == 1) this.onSaveStudentDeficiency()

          loading.pop()
          return this.$router.replace({ name: 'WaitingList' })
        })
        .catch(() => {
          toast.error('Não foi possível salvar o registro.', 'ERRO')
          loading.pop()
        })
    },

    onSaveEdit(){
      loading.push()
      WaitingListRepository.Update(this.waitingList)
        .then(result => {
          if(!IsSuccessCode(result)) return Promise.reject()

          if (this.waitingList.hasDeficiency == 1) this.onSaveStudentDeficiency()
          
          toast.success('Registro atualizado com sucesso', 'SUCESSO')
          loading.pop()
          return this.$router.replace({ name: 'WaitingList' })
        })
        .catch(() => {
          toast.error('Não foi possível atualizar o registro, insira local de treinamento e modalidade', 'ERRO')
          loading.pop()
        })
    },

    onSaveStudentDeficiency() {

      if (GuidUtils.isEmpty(this.waitingList.id)) return

      this.waitingListDeficiency = {
        id: this.waitingList.deficiencyId,
        waitingListId: this.waitingList.id,
        deficiencyTypeId: this.waitingList.cidCategory,
        cidCode: this.waitingList.cidCode
      }

      loading.push()
      WaitingListDeficiencyRepository.Create(this.waitingListDeficiency)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()
         
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao salvar deficiência', 'ERRO')
        })
    },

    getDeficiencyId() {
      loading.push()
      WaitingListDeficiencyRepository.GetById(this.waitingList.id)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          if (res.data.data.length <= 0) {
            this.disabledAllInputCid = false
            this.showButtonRemoveDeficiency = false
            loading.pop()
            return
          }

          const deficiency = res.data.data[0]

          this.waitingList.cidCode = deficiency.cidCode
          this.waitingList.cidCategory = deficiency.deficiencyTypeId
          this.waitingList.deficiencyId = deficiency.id

          this.disabledAllInputCid = true
          this.showButtonRemoveDeficiency = true

          this.getCidDescription(deficiency.cidCode)
         
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao pegar a deficiência da criança', 'ERRO')
        })
    },

    removeDeficiency(id) {
      if (!id) return 

      loading.push()
      WaitingListDeficiencyRepository.Delete(id)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          toast.success('Deficiência inativada com sucesso!', 'INATIVAR DEFICIÊNCIA')

          this.getById()

          this.cidDescription = null
          this.showModalRemoveDeficiency = false
         
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao deletar a deficiência da criança', 'ERRO')
        })
    },

    backTo() {
      this.$router.go(-1)
    },

    onDateContext(ctx) {      
      this.dateFormatted = ctx.selectedFormatted
    },

    formatDate(event) {
      this.waitingList.birthDate = dateFilter(event.currentTarget.value)
    },

    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref)
      }
      return null
    }
  }
}
